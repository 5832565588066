<template>
    <div>
        <div class="pageheader pd-t-25 pd-b-15">
            <div class="pd-t-5 pd-b-5">
                <h1 class="pd-0 mg-0 tx-20 text-overflow">Data Usulan Barang</h1>
            </div>
            <div class="breadcrumb pd-0 mg-0">
                <a class="breadcrumb-item" href="#"><i class="fa fa-check-square-o mg-r-5"></i>Data Master</a>
                <span class="breadcrumb-item active"> Data Usulan Barang</span>
            </div>
        </div> 

        <!-- MODALS ================================================== -->
        <modal name="my-modal" height="auto" :scrollable="true">
            <div class="modal-content">
                <div class="modal-card">
                    <div class="card-header">
                        <!-- Title -->
                        <h5 class="card-header-title">
                            Form Usulan Barang
                        </h5>
                    </div>
                    <form @submit.prevent="submitForm" autocomplete="on" enctype="multipart/form-data">
                        <div class="card-body">
                            <input type="hidden" value="" v-model="form.id">
                            <div class="form-group">
                                <label>Persediaan<span class="text-danger">*</span></label>
                                <v-select
                                    required=""
                                    :options="names"
                                    :reduce="(label) => label.code"
                                    label="label"
                                    v-model="form.idPersediaan"
                                    ></v-select>
                            </div>
                            <div v-if="form.code != ''" class="form-group">
                                <label>Kode</label>
                                <input required="" type="text" disabled v-model="form.code" value="" class="form-control" placeholder="">
                            </div>
                            <div class="form-group">
                                <label>Nama Barang<span class="text-danger">*</span></label>
                                <input required="" type="text" v-model="form.name" value="" class="form-control" placeholder="">
                            </div>
                        </div>
                        <div class="card-footer border-top-0">
                            <ul class="pagination mb-0"></ul>
                            <div class="d-flex justify-content-end">
                                <button type="button" class="btn btn-text text-muted" @click="hide_modal()">Batal</button>
                                <button type="submit" class="btn btn-primary">Simpan</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </modal>
        <!-- MAIN CONTENT ================================================== -->
        <div class="row justify-content-center">
            <div class="col-12 col-lg-12 col-xl-12">
                
                <!-- CONTENT -->
                <!-- <div class="text-center alert alert-danger" role="alert">
                   ⚠️ Untuk selanjutnya usulan barang akan di tinjau dan di setujui terlebih dahulu oleh admin, sebelum dapat digunakan !!!
                </div> -->

                <div class="tab-content mb-3">
                    <div class="tab-pane show active" id="anggota-tab" role="tabpanel">
                        <div class="card">
                            <div class="card-header">
                                <div class="input-group input-group-flush input-group-merge">
                                    <input type="search" class="form-control form-control-prepended search" v-model="keywords" v-on:keyup.enter="getData(1)" placeholder="Cari Persediaan...">
                                    <div class="input-group-prepend">
                                        <div class="input-group-text">
                                            <span class="fe fe-search"></span>
                                        </div>
                                    </div>
                                    <!-- HEADER -->
                                    <button v-if="userdata.parent != 0" class="btn btn-primary ml-2" @click="show_modal()">
                                        <div class="d-flex align-items-center">
                                            <i class="fe fe-plus mr-2"></i>Tambah Persediaan
                                        </div>
                                    </button>
                                </div>
                            </div>
                            <div class="table-responsive">
                                <table class="m-2 table table-sm table-striped  table-responsive-sm">
                                    <thead>
                                        <tr>
                                            <th style="width:4%">No</th>
                                            <th>Persediaan</th>
                                            <th>Kode Barang</th>
                                            <th>Nama Barang</th>
                                            <th v-if="userdata.role == 'administrator'">SKPD</th>
                                            <th class="text-center" style="width:10%">Aksi</th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="fetching">
                                        <tr>
                                            <td colspan="6">
                                                <div class="alert alert-primary" role="alert">
                                                    Sedang mengambil data..
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody v-if="!fetching">
                                        <tr v-for="(usulan, index) in dataUsulan" :key="index">
                                            <td>{{ (index + 1) + (pagination.currentPage - 1) * pagination.limit }}</td>
                                            <td>{{ usulan.persediaan }}</td>
                                            <td>{{ usulan.code }} {{ usulan.digunakan == 1 ? '✅' : '' }}</td>
                                            <td>{{ usulan.name }}</td>
                                            <td  v-if="userdata.role == 'administrator'">{{ usulan.pengusul }}</td>
                                            <td  class="text-right">
                                                <button v-if="usulan.status == 0 && userdata.role == 'administrator'" @click.prevent="acceptForm(usulan.id)" class="btn btn-success btn-sm mr-4"><i class="fe fe-check mr-2"></i>Setujui</button>
                                                <badge v-if="usulan.status === '1'" class="badge badge-success mr-4">Disetujui</badge>
                                                <badge v-if="usulan.status === '0'" class="badge badge-warning mr-4">Pengajuan</badge>
                                                <button v-if="userdata.parent != 0 && (userdata.role == 'administrator' || userdata.nickname == usulan.pengusul)" @click.prevent="editForm(usulan.id)" class="btn btn-warning btn-sm mr-2"><i class="fe fe-edit"></i></button>
                                                <button v-if="userdata.parent != 0 && userdata.role == 'administrator' && usulan.digunakan != 1" @click.prevent="postDelete(usulan.id)" class="btn btn-danger btn-sm mr-2"><i class="fe fe-trash-2"></i></button>
                                                <button v-if="userdata.parent != 0 && userdata.role == 'administrator' && usulan.digunakan == 1" @click.prevent="alertDelete()" class="btn btn-danger btn-sm mr-2"><i class="fe fe-trash-2"></i></button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="card-footer border-top-0">
                                <div style="float:right">
                                    <!-- <ul class="pagination mb-0"></ul> -->
                                    <v-pagination v-if="pagination.totalPages > 0" v-model="pagination.currentPage"
                                                :page-count="pagination.totalPages"
                                                :classes="pagination.bootstrapPaginationClasses"
                                                :labels="pagination.paginationAnchorTexts"
                                                v-on:change="getData(0)"></v-pagination>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- ADD SPACE -->
                <br><br><br>
            </div>
        </div>
        <!-- END MAIN CONTENT -->
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    import vPagination from 'vue-plain-pagination'
    import vSelect from "vue-select";
    import "vue-select/dist/vue-select.css";
    export default {
        metaInfo: {
            title: 'SI',
            titleTemplate: '%s - Data Usulan Barang'
        },
        data() {
            return {
                baseUrl: this.$apiconfig,
                dataUsulan: [],
                names: [],
                fetching: true,
                form: {
                    id: '',
                    idPersediaan: '',
                    code: '',
                    name: '',
                },
                pagination: {         
                    currentPage: 1,
                    totalPages: 0,
                    limit: 10,
                    bootstrapPaginationClasses: {
                        ul: 'pagination',
                        li: 'page-item',
                        liActive: 'active',
                        liDisable: 'disabled',
                        button: 'page-link'  
                    },
                    paginationAnchorTexts: {
                        first: '<i class="fe fe-chevrons-left"></i>',
                        prev: '<i class="fe fe-chevron-left"></i>',
                        next: '<i class="fe fe-chevron-right"></i>',
                        last: '<i class="fe fe-chevrons-right"></i>'
                    }
                },
                keywords: '',
                showSelectYear: true
                
            }
        },
        created() {
            this.$store.dispatch('loadUserData');
            this.getData(0);
            this.getPersediaan();
        },
        computed: mapState(['userdata']),
        components: {
            vPagination,
            'v-select' : vSelect,
        },
        methods: {
            show_modal() {
                this.showSelectYear = true;
                this.form.id = '';
                this.form.idPersediaan = '';
                this.form.code = '';
                this.form.name = '';
                this.$modal.show('my-modal');
            },
            hide_modal() {
                this.$modal.hide('my-modal')
            },
            // Get data data periode
            getData(key) {
                this.fetching = true;
                this.$http.get(this.baseUrl + 'admin/master/usulan', {
                    params: {
                        page : this.pagination.currentPage,
                        keywords: this.keywords,
                        key: key,
                    }
                })
                    .then((response) => {
                        this.dataUsulan = response.data.data;
                        this.fetching = false;
                        this.pagination.totalPages = response.data.total_page;
                    })
                    .catch((error) => {
                        console.log(error.response.data);
                        this.fetching = false;
                    });
            },
            // save periode
            submitForm() {
                if (this.form.idPersediaan == '') {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Persediaan tidak boleh kosong',
                    })
                } else {
                    this.$http.post(this.baseUrl + 'admin/master/usulan/save', this.form)
                        .then((response) => {
                            if(response.data.status){
                                this.$swal({
                                    icon: 'success',
                                    title: "success",
                                    text: 'Data Usulan berhasil disimpan',
                                    showConfirmButton: false,
                                    timer: 1500
                                })
                                this.$modal.hide('my-modal')
                                this.getData(0)
                                this.form.code = ''
                                this.form.name = ''
                            }else{
                                this.$swal({
                                    icon: 'error',
                                    title: 'Oops...',
                                    text: response.data.message,
                                    showConfirmButton: false,
                                    timer: 1500
                                })
                            }
                        })
                        .catch(error => {
                            console.log(error.response.data)
                        });
                }
            },
            // Edit data periode
            editForm(id) {
                this.$http.get(this.baseUrl + `admin/master/usulan/edit?id=${id}`)
                    .then((response) => {
                        this.form.id = response.data.data[0].id;
                        this.form.idPersediaan = response.data.data[0].id_persediaan;
                        this.form.code = response.data.data[0].code;
                        this.form.name = response.data.data[0].name;
                    })
                    .catch((error) => {
                        console.log(error.response.data);
                        this.fetching = false;
                    });
                this.$modal.show('my-modal');
            },
            // Delete data periode
            postDelete(id) {
                var fd = new FormData()
                fd.append('id', id)
                this.$swal({
                    title: 'Apakah kamu yakin?',
                    html: 'Jika usulan sudah digunakan, maka item pada transaksi masuk/keluar juga akan terhapus !!!',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                    if (result.value) {
                        this.$http({
                            url: this.baseUrl + 'admin/master/usulan/delete',
                            method: 'post',
                            data: fd
                        }).then((response) => {
                            this.$swal(
                                'Deleted!',
                                response.data.message,
                                'success'
                            )
                            this.getData(0);
                        }).catch(error => {
                            console.log(error.response.data)
                        })
                    }
                });
            },
            getPersediaan() {
                this.fetching = true;
                this.$http.get(this.baseUrl + 'admin/master/persediaan/selection', {
                    params: {
                        page : this.pagination.currentPage,
                        keywords: this.keywords,
                    }
                })
                    .then((response) => {
                        let data = response.data.data;
                        data.forEach((item) => {
                            this.names.push({
                            label: item.name,
                            code: item.id,
                            });
                        });
                    })
                    .catch((error) => {
                        console.log(error.response.data);
                    });
            },
            acceptForm(id) {
                var fd = new FormData()
                fd.append('id', id)
                this.$swal({
                    title: 'Apakah kamu yakin?',
                    html: 'Usulan yang disetujui dapat digunakan untuk transaksi',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Ya, Setujui!'
                }).then((result) => {
                    if (result.value) {
                        this.$http({
                            url: this.baseUrl + 'admin/master/usulan/accept',
                            method: 'post',
                            data: fd
                        }).then((response) => {
                            this.$swal(
                                'success!',
                                response.data.message,
                                'success'
                            )
                            this.getData();
                        }).catch(error => {
                            console.log(error.response.data)
                        })
                    }
                });
            },
            alertDelete: function() {
                this.$swal({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Usulan tidak dapat dihapus karena sudah digunakan!',
                })
            }
        },
    };
</script>
<style>
    /* style sweetalert */
    .swal2-html-container {
        display: block;
        text-align: center;
    }
</style>